import React from "react";
import {
  ArrowRight,
  Globe,
  BarChart4,
  Server,
  Database,
  ShieldAlert,
} from "lucide-react";

export default function NetworkConnectSection() {
  return (
    <div className="w-full bg-black text-white pb-16 px-4">
      <div className="mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left side: Heading and text */}
        <div className="flex flex-col justify-center space-y-6">
          <h3 className="text-sm pt-24 sm:pt-0 uppercase tracking-wider text-purple-400">
            Network and Connect
          </h3>
          <h2 className="text-3xl sm:text-4xl font-semibold leading-snug">
            Interconnect your application seamlessly
            with highly performant networking
          </h2>
          <p className="text-gray-300 leading-relaxed">
            Railway provides automated service discovery, blazing fast
            networking, and support for any protocol, all out of the box.
          </p>
          <a
            href="#"
            className="inline-flex items-center text-purple-400 hover:text-purple-300 transition-colors group"
          >
            Learn More
            <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-0.5 transition-transform" />
          </a>
        </div>

        {/* Right side: Diagram container */}
        <div className="relative h-[600px] md:h-[450px] w-full mt-8 md:mt-0">
          {/* Connection lines with flow animation - Adjusted for mobile */}
          {/* Frontend to Analytics */}
          <div className="absolute top-[85px] left-[20px] md:left-[180px] w-[100px] h-0.5 bg-gradient-to-r from-purple-700/50 via-purple-500/30 to-purple-700/50 animate-pulse" />
          
          {/* Frontend to Backend */}
          <div className="absolute top-[130px] left-[40px] md:left-[100px] w-0.5 h-[120px] bg-gradient-to-b from-purple-700/50 via-purple-500/30 to-purple-700/50 animate-pulse" />
          
          {/* Analytics to API Gateway */}
          <div className="absolute top-[130px] left-[180px] md:left-[340px] w-0.5 h-[80px] bg-gradient-to-b from-purple-700/50 via-purple-500/30 to-purple-700/50 animate-pulse" />
          
          {/* Backend to API Gateway */}
          <div className="absolute top-[220px] left-[80px] md:left-[180px] w-[100px] h-0.5 bg-gradient-to-r from-purple-700/50 via-purple-500/30 to-purple-700/50 animate-pulse" />
          
          {/* API Gateway to Postgres */}
          <div className="absolute top-[260px] left-[180px] md:left-[340px] w-0.5 h-[80px] bg-gradient-to-b from-purple-700/50 via-purple-500/30 to-purple-700/50 animate-pulse" />
          
          {/* Backend to Postgres */}
          <div className="absolute top-[260px] left-[80px] md:left-[180px] w-[160px] h-[80px] border-b border-r border-purple-700/50 rounded-br-lg" />

          {/* Animated dots - Adjusted for mobile */}
          <div className="absolute top-[83px] left-[80px] md:left-[180px] w-2 h-2 rounded-full bg-purple-400 animate-ping" />
          <div className="absolute top-[218px] left-[80px] md:left-[180px] w-2 h-2 rounded-full bg-purple-400 animate-ping" />
          <div className="absolute top-[340px] left-[178px] md:left-[338px] w-2 h-2 rounded-full bg-purple-400 animate-ping" />

          {/* Frontend box */}
          <div className="absolute top-[60px] left-[20px] md:left-[40px] bg-gray-900 border border-gray-700 rounded-lg p-4 shadow-md w-44 hover:border-purple-500 transition-colors">
            <div className="flex items-center space-x-2 mb-2">
              <Globe className="text-purple-400 w-5 h-5" />
              <h4 className="font-semibold">frontend</h4>
            </div>
            <p className="text-sm text-gray-400">project.myapp</p>
          </div>

          {/* Analytics box */}
          <div className="absolute top-[60px] left-[160px] md:left-[320px] bg-gray-900 border border-gray-700 rounded-lg p-4 shadow-md w-44 hover:border-purple-500 transition-colors">
            <div className="flex items-center space-x-2 mb-2">
              <BarChart4 className="text-purple-400 w-5 h-5" />
              <h4 className="font-semibold">analytics</h4>
            </div>
            <p className="text-sm text-gray-400">tracking data</p>
          </div>

          {/* Backend box */}
          <div className="absolute top-[200px] left-[20px] md:left-[40px] bg-gray-900 border border-gray-700 rounded-lg p-4 shadow-md w-44 hover:border-purple-500 transition-colors">
            <div className="flex items-center space-x-2 mb-2">
              <Server className="text-purple-400 w-5 h-5" />
              <h4 className="font-semibold">backend</h4>
            </div>
            <p className="text-sm text-gray-400">processing</p>
          </div>

          {/* API Gateway box */}
          <div className="absolute top-[200px] left-[160px] md:left-[320px] bg-gray-900 border border-gray-700 rounded-lg p-4 shadow-md w-44 hover:border-purple-500 transition-colors">
            <div className="flex items-center space-x-2 mb-2">
              <ShieldAlert className="text-purple-400 w-5 h-5" />
              <h4 className="font-semibold">api gateway</h4>
            </div>
            <p className="text-sm text-gray-400">routing</p>
          </div>

          {/* Postgres box */}
          <div className="absolute top-[340px] left-[160px] md:left-[320px] bg-gray-900 border border-gray-700 rounded-lg p-4 shadow-md w-44 hover:border-purple-500 transition-colors">
            <div className="flex items-center space-x-2 mb-2">
              <Database className="text-purple-400 w-5 h-5" />
              <h4 className="font-semibold">postgres</h4>
            </div>
            <p className="text-sm text-gray-400">db data</p>
          </div>
        </div>
      </div>
    </div>
  );
}