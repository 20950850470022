import React from "react";

export default function FeaturesGrid() {
  return (
    <div className="bg-black text-white py-16 px-4">
      <div className="mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Card 1: Services */}
        <div className="bg-gray-900 border border-gray-800 p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-lg font-semibold text-purple-400">Services</h3>
          <p className="text-gray-300">
            Deploy any web service, private or public.
          </p>
          <ul className="list-disc list-inside text-sm text-gray-400 space-y-1">
            <li>GitHub repository</li>
          </ul>
        </div>

        {/* Card 2: Databases */}
        <div className="bg-gray-900 border border-gray-800 p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-lg font-semibold text-purple-400">Databases</h3>
          <p className="text-gray-300">
            Spin up databases with built-in backups.
          </p>
          <ul className="list-disc list-inside text-sm text-gray-400 space-y-1">
            <li>PostgreSQL</li>
            <li>Redis</li>
          </ul>
        </div>

        {/* Card 3: Volumes */}
        <div className="bg-gray-900 border border-gray-800 p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-lg font-semibold text-purple-400">Volumes</h3>
          <p className="text-gray-300">
            Attach and mount high-performance persistent storage volumes.
          </p>
          <ul className="list-disc list-inside text-sm text-gray-400 space-y-1">
            <li>Up to 256GB of storage</li>
            <li>10,000+ IOPS</li>
            <li>Disk usage metrics</li>
          </ul>
        </div>

        {/* Card 4: Cron Jobs */}
        <div className="bg-gray-900 border border-gray-800 p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-lg font-semibold text-purple-400">Cron Jobs</h3>
          <p className="text-gray-300">
            Configure a job to run on a fixed schedule.
          </p>
          <ul className="list-disc list-inside text-sm text-gray-400 space-y-1">
            <li>Atomic to 5-minute intervals</li>
            <li>Starrable</li>
            <li>Customizable via cron expression</li>
          </ul>
        </div>

        {/* Card 5: Templates */}
        <div className="bg-gray-900 border border-gray-800 p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-lg font-semibold text-purple-400">Private Web services</h3>
          <p className="text-gray-300">
            Specify your own security rules
          </p>
          <ul className="list-disc list-inside text-sm text-gray-400 space-y-1">
            <li>Customizables</li>
            <li>Easily manageable from dashboard</li>
          </ul>
        </div>

        {/* Card 6: Variables */}
        <div className="bg-gray-900 border border-gray-800 p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-lg font-semibold text-purple-400">Runtimes</h3>
          <p className="text-gray-300">
            Deploy to different runtimes
          </p>
          <ul className="list-disc list-inside text-sm text-gray-400 space-y-1">
            <li>Node</li>
            <li>Python</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
