import React, { useEffect } from "react";
import { Github } from "lucide-react";

const InstallGithub = ({ isGithubAppInstalled, darkMode, handleGithubAppInstallation }) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('installation_id')) {
            localStorage.setItem('githubInstalled', 'true');
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []); 
 
    return (
        <div className={`flex flex-col items-center justify-center min-h-[400px] p-8 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-sm`}>
            <div className="text-center max-w-md">
                <div className="mb-6">
                    <div className={`mx-auto w-16 h-16 ${darkMode ? 'bg-indigo-900/30' : 'bg-indigo-100'} rounded-full flex items-center justify-center`}>
                        <Github className={`w-8 h-8 ${darkMode ? 'text-indigo-400' : 'text-indigo-600'}`} />
                    </div>
                </div>

                <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'} mb-4`}>
                    Connect Your GitHub App
                </h2>

                {isGithubAppInstalled() ? (
                    <div className="space-y-4">
                        <div className="flex items-center justify-center space-x-2 text-green-600">
                            <svg className={`w-5 h-5 ${darkMode ? 'text-green-400' : 'text-green-600'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            <p className={`text-lg font-medium ${darkMode ? 'text-green-400' : 'text-green-600'}`}>GitHub App Successfully Installed</p>
                        </div>
                        <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                            Your GitHub integration is now complete and ready to use.
                        </p>
                    </div>
                ) : (
                    <div className="space-y-4">
                        <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-6`}>
                            Connect your GitHub repository to enable automatic deployments and seamless integration with your workflow.
                        </p>
                        <button
                            onClick={handleGithubAppInstallation}
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                        >
                            <Github className="w-5 h-5 mr-2" />
                            Install GitHub App
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InstallGithub;