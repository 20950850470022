import React from "react";
import {
  Server,
  Rocket,
  RefreshCw,
  CheckSquare,
  GitBranch,
  PlayCircle,
} from "lucide-react";

export default function Stages() {
  return (
    <div className="bg-black text-white px-4">
      {/* Container */}
      <div className="mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-3 gap-8">
        
        {/* Step 1 */}
        <div className="flex flex-col items-start border border-gray-700 rounded-lg p-6">
          {/* Icon */}
          <div className="mb-4">
            <Server className="w-8 h-8 text-green-400" />
          </div>
          {/* Title */}
          <h2 className="text-xl font-bold mb-2">Choose your service type</h2>
          {/* Description */}
          <p className="text-sm text-gray-300 mb-4">
            Spin up web services, static sites, cron jobs, and more.
          </p>
          {/* Example selection UI */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <CheckSquare className="w-5 h-5 text-green-400" />
              <span className="text-sm">Web servers</span>
            </div>
            <div className="flex items-center space-x-2">
              <CheckSquare className="w-5 h-5 text-gray-600" />
              <span className="text-sm">Managed Redis</span>
            </div>
            <div className="flex items-center space-x-2">
              <CheckSquare className="w-5 h-5 text-gray-600" />
              <span className="text-sm">Managed PostgreSQL</span>
            </div>
            <div className="flex items-center space-x-2">
              <CheckSquare className="w-5 h-5 text-gray-600" />
              <span className="text-sm">Cron jobs</span>
            </div>
          </div>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-start border border-gray-700 rounded-lg p-6">
          {/* Icon */}
          <div className="mb-4">
            <Rocket className="w-8 h-8 text-green-400" />
          </div>
          {/* Title */}
          <h2 className="text-xl font-bold mb-2">Deploy in seconds</h2>
          {/* Description */}
          <p className="text-sm text-gray-300 mb-4">
            Build and run your apps with lightning speed and flexible config.
          </p>
          {/* Example form fields */}
          <div className="space-y-2 w-full">
            <label className="block text-sm">
              Branch
              <div className="flex items-center border border-gray-600 rounded-md mt-1">
                <GitBranch className="w-4 h-4 ml-2 text-gray-400" />
                <input
                  type="text"
                  value="main"
                  className="bg-transparent w-full py-1 px-2 outline-none text-white"
                  readOnly
                />
              </div>
            </label>
            <label className="block text-sm">
              Build command
              <div className="flex items-center border border-gray-600 rounded-md mt-1">
                <PlayCircle className="w-4 h-4 ml-2 text-gray-400" />
                <input
                  type="text"
                  value="npm install"
                  className="bg-transparent w-full py-1 px-2 outline-none text-white"
                  readOnly
                />
              </div>
            </label>
            <label className="block text-sm">
              Start command
              <div className="flex items-center border border-gray-600 rounded-md mt-1">
                <PlayCircle className="w-4 h-4 ml-2 text-gray-400" />
                <input
                  type="text"
                  value="node index.js"
                  className="bg-transparent w-full py-1 px-2 outline-none text-white"
                  readOnly
                />
              </div>
            </label>
          </div>
          {/* Deploy button */}
          <button className="mt-4 bg-purple-600 hover:bg-purple-500 text-white text-sm font-medium px-4 py-2 rounded-md">
            Deploy
          </button>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-start border border-gray-700 rounded-lg p-6">
          {/* Icon */}
          <div className="mb-4">
            <RefreshCw className="w-8 h-8 text-green-400" />
          </div>
          {/* Title */}
          <h2 className="text-xl font-bold mb-2">Update automatically</h2>
          {/* Description */}
          <p className="text-sm text-gray-300 mb-4">
            Stay current with your code thanks to seamless, automatic redeploys.
          </p>
          {/* Example update UI */}
          <div className="space-y-2 w-full">
            <label className="block text-sm">
              Git Push
              <div className="flex items-center border border-gray-600 rounded-md mt-1">
                <span className="ml-2 text-gray-400">https://yoursite.com</span>
              </div>
            </label>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm">Automatic Deploy</span>
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-purple-600"
                defaultChecked
              />
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm">Manual Deploy</span>
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-purple-600"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
