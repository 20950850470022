import React, { useState } from "react";

const Environment = ({ password, darkMode, ip, deploymentId, userId }) => {
    const [envVars, setEnvVars] = useState([{ key: '', value: '' }]);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleEnvVarChange = (index, field, value) => {
        setEnvVars(prevVars => {
            const newEnvVars = [...prevVars];
            newEnvVars[index] = { ...newEnvVars[index], [field]: value };
            return newEnvVars;
        });
    };

    const addEnvVar = () => {
        setEnvVars(prevVars => [...prevVars, { key: '', value: '' }]);
    };

    const removeEnvVar = (index) => {
        setEnvVars(prevVars => prevVars.filter((_, i) => i !== index));
    };

    const updateEnvironmentVariables = async () => {
        setIsUpdating(true);
        try {
            const response = await fetch('https://backend.finisimo-cloud.com/update-env-variables', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password,
                    ip,
                    deploymentId,
                    envVars: envVars.filter(env => env.key.trim() !== '' && env.value.trim() !== '')
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update environment variables');
            }

            const data = await response.json();
            console.log('Successfully updated environment variables:', data);

        } catch (error) {
            console.error('Error updating environment variables:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    const hasValidEnvVars = envVars.length > 0 && envVars.some(
        env => env.key.trim() !== '' && env.value.trim() !== ''
    );

    return (
        <div className="px-6 py-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                    Environment Variables
                </h2>
                <button
                    type="button"
                    onClick={updateEnvironmentVariables}
                    disabled={!hasValidEnvVars || isUpdating}
                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                ${!hasValidEnvVars || isUpdating
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        }`}
                >
                    {isUpdating ? (
                        <>
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Updating...
                        </>
                    ) : (
                        'Update'
                    )}
                </button>
            </div>

            <div className="relative">
                <div className="space-y-3">
                    {envVars.map((envVar, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <input
                                type="text"
                                placeholder="Key"
                                value={envVar.key}
                                onChange={(e) => handleEnvVarChange(index, 'key', e.target.value)}
                                className={`block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${darkMode
                                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                                    : 'border-gray-300 border-gray-300 border text-gray-900'
                                    }`}
                            />
                            <input
                                type="text"
                                placeholder="Value"
                                value={envVar.value}
                                onChange={(e) => handleEnvVarChange(index, 'value', e.target.value)}
                                className={`block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${darkMode
                                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                                    : 'border-gray-300 border-gray-300 border text-gray-900'
                                    }`}
                            />
                            <button
                                type="button"
                                onClick={() => removeEnvVar(index)}
                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>

                <button
                    type="button"
                    onClick={addEnvVar}
                    className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-green-500"
                >
                    Add Environment Variable
                </button>
            </div>
        </div>
    );
};

export default Environment;