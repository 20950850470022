import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import FeaturesGrid from "./featuresgrid";
import { Cloud, DollarSign, Cpu, MemoryStickIcon, ArrowRight } from 'lucide-react';
import DevDashboard from "./DevDashboard"
import NetworkConnectSection from "./networking";
import { CloudLightning, RefreshCcw, Briefcase, Package, Settings } from 'lucide-react';
import { Link } from "react-router-dom";
import logo from "../assets/no_bg_fino.png"
import Stages from "./stages"
import Footer from "./footer";
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import {
    Github,
    Globe,
    Server,
    Terminal,
    RefreshCw,
    Lock,
} from 'lucide-react';
import { Zap, Users } from 'lucide-react';

const Home = ({ signOutWithGitHub, signInWithGitHub, user}) => {

    const data = [
        { month: 'Jan', 'My Blog': 0.8, 'Company Website': 1.2, 'Side Project': 0.7 },
        { month: 'Feb', 'My Blog': 0.6, 'Company Website': 0.9, 'Side Project': 0.5 },
        { month: 'Mar', 'My Blog': 0.4, 'Company Website': 0.5, 'Side Project': 0.3 },
        { month: 'Apr', 'My Blog': 0.6, 'Company Website': 0.8, 'Side Project': 0.6 },
        { month: 'May', 'My Blog': 1.0, 'Company Website': 1.2, 'Side Project': 0.9 }
    ];

    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const features = [
        {
            icon: Zap,
            title: "Build with speed",
            description: "Link your repo and go live—then iterate with every push.",
            subFeatures: [
                "Automatic Deploys",
                "Native Language Runtimes",
                "Enterprise-Grade Data Stores",
                "Private Network"
            ]
        },
        {
            icon: Cloud,
            title: "Scale with confidence",
            description: "Grow your infrastructure, team, and business with a platform that scales alongside you.",
            subFeatures: [
                "Load-Based Autoscaling",
                "Infrastructure as Code",
                "DDoS Protection",
                "Data Privacy"
            ]
        },
        {
            icon: Users,
            title: "Ship together",
            description: "Collaborate with your team on everything from one-line fixes to full-scale migrations.",
            subFeatures: [
                "Project Management",
                "Preview Environments",
                "Platform Notifications",
                "Role-Based Access"
            ]
        }
    ];

    const steps = [
        {
            icon: Globe,
            title: "Go to Fino",
            description: "Visit our website and sign up or log in to your account.",
        },
        {
            icon: Github,
            title: "Connect GitHub",
            description: "Link your GitHub account to access your repositories.",
        },
        {
            icon: Server,
            title: "Create Web Service",
            description: "Click 'Web Service' in your dashboard to start a new deployment.",
        },
        {
            icon: Terminal,
            title: "Configure Deployment",
            description: "Select your repository, set the name, location, and other details.",
        },
        {
            icon: RefreshCw,
            title: "Deploy",
            description: "Click 'Deploy' and wait for the process to complete.",
        },
        {
            icon: Lock,
            title: "Access Your App",
            description: "Use the provided HTTPS endpoint to securely access your deployed application.",
        }
    ];

    const goToDashboard = () => {
        navigate("/dashboard");
    }

    const pricingData = [
        { tier: 'Type A', ram: '512MB', cpu: '0.5', renderPrice: '$7/month', finoPrice: '$4/month' },
        { tier: 'Type B', ram: '2GB', cpu: '1', renderPrice: '$25/month', finoPrice: '$18/month' },
        { tier: 'Type C', ram: '4GB', cpu: '2', renderPrice: '$85/month', finoPrice: '$40/month' },
        { tier: 'Type D', ram: '8GB', cpu: '4', renderPrice: '$175/month', finoPrice: '$80/month' },
        { tier: 'Type E', ram: '32GB', cpu: '8', renderPrice: '$450/month', finoPrice: '$260/month' }
    ]; 


    return ( 
        <div className="bg-black flex flex-col min-h-screen">
        {/* Header */}
        <header className="border-b border-gray-700">
          <div className="container mx-auto flex items-center py-4 px-6">
            {/* Logo */}
            <div className="flex w-1/3 justify-center items-center">
              <img className="w-24 h-auto" src={logo} alt="Logo" />
            </div>
  
            {/* Nav Links */}
            <div className="flex w-1/3 justify-evenly items-center space-x-4">
              <div className="hidden md:flex space-x-8">
                <Link
                  to="/price-comparison"
                  className="text-purple-400 text-sm hover:text-purple-300 transition-colors"
                >
                  Price comparison
                </Link>
              </div>
              <div className="hidden md:flex space-x-8">
                <Link
                  to="/pricing"
                  className="text-purple-400 text-sm hover:text-purple-300 transition-colors"
                >
                  Pricing
                </Link>
              </div>
              <a
                href="mailto:hector.diaz@pucp.edu.pe"
                className="hidden md:flex text-purple-400 text-sm hover:text-purple-300 transition-colors"
              >
                Contact
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://vimeo.com/1063656514"
                className="hidden md:flex text-purple-400 text-sm hover:text-purple-300 transition-colors"
              >
                Tutorial
              </a>
              {user && (
                <Link
                  to="/dashboard"
                  className="hidden md:flex text-purple-400 text-sm hover:text-purple-300 transition-colors"
                >
                  Dashboard
                </Link>
              )}
            </div>
  
            {/* Auth Button */}
            <div className="w-1/3 flex justify-center">
              <button
                onClick={user ? signOutWithGitHub : signInWithGitHub}
                className="bg-transparent border border-purple-600 text-white px-6 py-2 rounded-md font-semibold transition-all hover:bg-purple-600 hover:text-white"
              >
                {user ? "Logout" : "Login"}
              </button>
            </div>
  
            {/* Mobile Menu Toggle */}
            <button
              className="md:hidden text-gray-300 ml-4"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
  
          {/* Mobile Menu */}
          {mobileMenuOpen && (
            <nav className="bg-black/90 border-t border-gray-700 md:hidden">
              <div className="px-6 py-4 space-y-2">
                <Link
                  to="/pricing"
                  className="block text-purple-400 text-sm hover:text-purple-300 transition-colors"
                >
                  Pricing
                </Link>
                <a
                  href="mailto:hector.diaz@pucp.edu.pe"
                  className="block text-purple-400 text-sm hover:text-purple-300 transition-colors"
                >
                  Contact
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://vimeo.com/1063656514"
                  className="block text-purple-400 text-sm hover:text-purple-300 transition-colors"
                >
                  Tutorial
                </a>
                {user && (
                  <Link
                    to="/dashboard"
                    className="block text-purple-400 text-sm hover:text-purple-300 transition-colors"
                  >
                    Dashboard
                  </Link>
                )}
              </div>
            </nav>
          )}
        </header>
  
        {/* Main (Hero Section) */}
        <main className="flex-grow">
          <section className="py-20">
            <div className="container mx-auto text-center px-4 relative z-10">
              <p className="font-semibold mb-6 mx-auto text-purple-400">
                Built for devs who ship
              </p>
                <div className="container mx-auto text-center pb-20 px-4 max-w-3xl">
                  <p className="text-5xl font-bold text-white leading-tight mb-6">
                    Your{" "}
                    <span className="bg-gradient-to-r from-purple-600 to-blue-400 bg-clip-text text-transparent">
                      cheapest
                    </span>{" "}
                    and{" "}
                    <span className="bg-gradient-to-r from-purple-600 to-blue-400 bg-clip-text text-transparent">
                      fastest
                    </span>{" "}
                    path to production
                  </p>
                  <p className="text-lg text-gray-300 leading-relaxed mb-8">
                    Fino is a platform that allows developers to focus on building rather than on deploying.{" "}
                    <span className="font-medium text-white">
                      Cheap prices + unparalleled developer experience
                    </span>{" "}
                    so that you can deploy your web services in minutes.
                  </p>
                  <p className="text-white font-semibold text-lg">
                    Up to 12x cheaper than Heroku!
                  </p>
                </div>

              <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                <button
                  onClick={user ? goToDashboard : signInWithGitHub}
                  className="bg-purple-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-purple-500 transition-colors"
                >
                  {user ? "Go to dashboard" : "Get Started for Free"}
                </button>
                <Link 
                  to="/price-comparison"
                  className="bg-transparent border border-purple-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-purple-600 transition-colors"
                >
                  See price comparison
                </Link>
              </div>
            </div>
          </section>
                <Stages/>
                <NetworkConnectSection/>
                <FeaturesGrid/>
            </main>
            <Footer />
        </div>
    );
};

export default Home;