import React, { useEffect, useRef } from "react";

const Logs = ({ logs }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [logs]);

    return (
        <div className="px-6 py-6 max-w-6xl">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Console</h2>
            <div className="relative">
                <div
                    ref={containerRef}
                    className="bg-gray-900 rounded-lg text-white p-4 font-mono text-sm overflow-y-auto"
                    style={{ height: '400px', maxWidth: '100%' }}
                >
                    {logs?.length === 0 ? (
                        <p className="text-gray-400">No logs available.</p>
                    ) : (
                        <ul className="space-y-2">
                                {logs?.map((log) => {
                                    let dateObj;
                                    if (log?.timestamp && typeof log.timestamp.toDate === "function") {
                                        dateObj = log.timestamp.toDate();
                                    } else if (log?.timestamp && typeof log.timestamp === "string") {
                                        dateObj = new Date(log.timestamp);
                                    } else {
                                        dateObj = new Date();
                                    }

                                    return (
                                        <li key={log?.id} className="leading-relaxed break-words whitespace-normal">
                                            <span className="text-green-400">{log?.logName}</span>:{" "}
                                            <span className="text-gray-300 break-all">{log?.content}</span>{" "}
                                            <span className="text-gray-500 text-xs">
                                                {dateObj.toLocaleString()}
                                            </span>
                                        </li>
                                    );
                                })}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Logs;
