import React, { useState, useEffect } from "react";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { Activity, Clock, AlertCircle, CheckCircle2, Play, Flag } from "lucide-react";

const Events = ({ db, darkMode, deploymentId, userId }) => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!deploymentId || !userId) return;

        const eventsQuery = query(
            collection(db, "events"),
            where("deploymentId", "==", deploymentId),
            where("userId", "==", userId),
            orderBy("timestamp", "desc")
        );

        const unsubscribe = onSnapshot(
            eventsQuery,
            (querySnapshot) => {
                const eventsArray = [];
                querySnapshot.forEach((doc) => {
                    eventsArray.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setEvents(eventsArray);
                setLoading(false);
            },
            (error) => {
                console.error("Error fetching events:", error);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, [db, deploymentId, userId]);

    const getEventIcon = (eventType) => {
        switch (eventType?.toLowerCase()) {
            case 'new deployment':
                return <Play className="w-5 h-5 text-blue-500" />;
            case 'update':
                return <Play className="w-5 h-5 text-blue-500" />;
            case 'deployment finished':
                return <CheckCircle2 className="w-5 h-5 text-green-500" />;
            case 'error':
                return <AlertCircle className="w-5 h-5 text-red-500" />;
            case 'completed':
                return <Flag className="w-5 h-5 text-green-500" />;
            default:
                return <Activity className={`w-5 h-5 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />;
        }
    };

    if (loading) {
        return (
            <div className="px-6 py-6">
                <h2 className={`text-lg font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Events</h2>
                <div className="flex items-center justify-center h-32">
                    <Activity className={`w-6 h-6 ${darkMode ? 'text-gray-500' : 'text-gray-400'} animate-pulse`} />
                </div>
            </div>
        );
    }

    return (
        <div className="px-6 py-6">
            <h2 className={`text-lg font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Events</h2>
            <div className="relative">
                {events.length === 0 ? (
                    <div className={`flex flex-col items-center justify-center h-32 rounded-lg border-2 border-dashed ${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-50 border-gray-200'}`}>
                        <Activity className={`w-8 h-8 mb-2 ${darkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                        <p className={darkMode ? 'text-gray-400' : 'text-gray-500'}>No events available</p>
                    </div>
                ) : (
                    <div className="flow-root">
                        <ul className="space-y-4">
                            {events.map((event) => (
                                <li className="border border-gray-300" key={event.id}>
                                    <div className="relative pb-4">
                                        <div className="relative flex items-center space-x-3">
                                            <div className="flex pl-4 justify-center items-center justify-center">
                                                {getEventIcon(event.event)}
                                            </div>
                                            <div className={`min-w-0 flex-1 rounded-lg shadow-sm p-4 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                                                <div className="flex flex-col gap-1">
                                                    <p className={`text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                                        {event.event}
                                                    </p>
                                                    <div className={`flex items-center text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                                        <Clock className="w-4 h-4 mr-1" />
                                                        {event.timestamp?.toDate().toLocaleString() || 'Time not available'}
                                                    </div>
                                                </div>
                                                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'} mt-2`}>
                                                    {event.content}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Events;