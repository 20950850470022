import React, { useState } from 'react';
import { Cpu, LucideMemoryStick, Server, Sparkles } from 'lucide-react';

const Bplans = [
    { plan: "Hobby", price: "Free for 3 months", cpu: "0.1", ram: "816MB", variantId: "531421" },
    { plan: "Type A", price: "$4/month", cpu: "0.5", ram: "512MB", variantId: "531421" },
    { plan: "Type B", price: "$18/month", cpu: "1", ram: "2GB", variantId: "531422" },
    { plan: "Type C", price: "$40/month", cpu: "2", ram: "4GB", variantId: "531423" },
    { plan: "Type D", price: "$80/month", cpu: "4", ram: "8GB", variantId: "531424" },
    { plan: "Type E", price: "$160/month", cpu: "4", ram: "16GB", variantId: "531425" }
];

const Scale = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    return (
        <div className="px-6 py-6">
            <div className="flex items-center gap-2">
                <Server className="w-5 h-5 text-indigo-600" />
                <h2 className="text-lg font-semibold text-gray-900">Scale</h2>
            </div>

            {/* Beta Testing Message */}
            <div className="mt-6 mb-8 rounded-lg border border-indigo-200 bg-indigo-50 p-4">
                <div className="flex items-start gap-3">
                    <Sparkles className="w-5 h-5 flex-shrink-0 text-indigo-600" />
                    <div>
                        <h3 className="text-base font-semibold text-indigo-700 mb-1">
                            Beta Testing Period - All Features Free!
                        </h3>
                        <p className="text-sm text-gray-700 leading-relaxed">
                            We're excited to have you join our beta testing phase! Currently, all features are available completely free of charge for 3 months. The pricing plans below represent our future pricing structure after the beta period.
                        </p>
                        <p className="mt-2 text-sm font-medium text-indigo-600">
                            ✨ Early adopters like you will receive special benefits when we launch officially.
                        </p>
                    </div>
                </div>
            </div>

            <div className="relative">
                <div className="mt-8">
                    <h3 className="text-lg font-semibold mb-6">Future Pricing Plans</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {Bplans.map((plan) => (
                            <div
                                key={plan.plan}
                                onClick={() => handlePlanSelect(plan)}
                                className={`border p-4 cursor-pointer transition duration-300 
                                    ${selectedPlan?.plan === plan.plan
                                        ? 'border-indigo-600 bg-indigo-50'
                                        : 'border-indigo-600 hover:bg-gray-50'
                                    }`}
                            >
                                <div className="flex justify-between">
                                    <div>
                                        <h4 className="text-base font-medium mb-1">{plan.plan}</h4>
                                        <p className="text-lg font-semibold mb-3">
                                            {plan.price}
                                            <span className="text-xs font-normal text-gray-600"></span>
                                        </p>
                                    </div>
                                    <div className="text-sm text-gray-600">
                                        <div className="flex items-center gap-1 mb-1">
                                            <Cpu className="w-4 h-4 text-indigo-600" />
                                            <p>CPU: {plan.cpu} core(s)</p>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <LucideMemoryStick className="w-4 h-4 text-indigo-600" />
                                            <p>RAM: {plan.ram}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Scale;