import React, {useEffect} from 'react';
import { DollarSign, ExternalLink, Check } from 'lucide-react';
import Header from "../header";
import Footer from "../footer";

const PriceComparison = ({ user, signInWithGitHub, signOutWithGitHub }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const herokuComparisons = [
        {
            tier: "Basic (512MB RAM)",
            fino: 4,
            heroku: 7,
            savings: "1.75x cheaper",
            ram: "512MB",
            cpu: "0.5 CPU"
        },
        {
            tier: "Standard (2GB RAM)",
            fino: 18,
            heroku: 50,
            savings: "2.77x cheaper",
            ram: "2GB",
            cpu: "1 CPU"
        },
        {
            tier: "Performance (4GB RAM)",
            fino: 40,
            heroku: 400,
            savings: "10x cheaper",
            ram: "4GB",
            cpu: "2 CPU"
        },
        {
            tier: "Performance (8GB RAM)",
            fino: 80,
            heroku: 800,
            savings: "10x cheaper",
            ram: "8GB",
            cpu: "4 CPU"
        },
        {
            tier: "Performance (32GB RAM)",
            fino: 260,
            heroku: 3200,
            savings: "12.3x cheaper",
            ram: "32GB",
            cpu: "8 CPU"
        }
    ];

    const renderComparisons = [
        {
            tier: "Type A",
            fino: 4,
            render: 7,
            savings: "1.75x cheaper",
            ram: "512MB",
            cpu: "0.5 CPU"
        },
        {
            tier: "Type B",
            fino: 18,
            render: 25,
            savings: "1.4x cheaper",
            ram: "2GB",
            cpu: "1 CPU"
        },
        {
            tier: "Type C",
            fino: 40,
            render: 85,
            savings: "2.1x cheaper",
            ram: "4GB",
            cpu: "2 CPU"
        },
        {
            tier: "Type D",
            fino: 80,
            render: 175,
            savings: "2.2x cheaper",
            ram: "8GB",
            cpu: "4 CPU"
        },
        {
            tier: "Type E",
            fino: 260,
            render: 450,
            savings: "1.7x cheaper",
            ram: "32GB",
            cpu: "8 CPU"
        }
    ];

    const ComparisonTable = ({ data, competitor }) => {
        const competitorKey = competitor.toLowerCase();

        // Mobile table card component - now more compact
        const MobileTableCard = ({ item }) => (
            <div className="bg-gray-800 p-4 rounded-lg mb-4">
                <div className="flex justify-between items-start mb-2">
                    <div>
                        <h3 className="text-white font-medium">{item.tier}</h3>
                        <p className="text-xs text-gray-400">{item.ram} • {item.cpu}</p>
                    </div>
                    <span className="px-2 py-1 bg-green-900/30 text-green-400 rounded-full text-xs">
                        {item.savings}
                    </span>
                </div>
                <div className="flex items-center justify-between mt-3 text-sm">
                    <div className="flex items-center gap-4">
                        <div>
                            <div className="text-gray-400 mb-1">Fino</div>
                            <div className="flex items-center text-white">
                                <DollarSign className="w-4 h-4 text-blue-400" />
                                <span className="font-medium">{item.fino}</span>
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-400 mb-1">{competitor}</div>
                            <div className="flex items-center text-gray-300">
                                <DollarSign className="w-4 h-4" />
                                <span>{item[competitorKey]}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        // Desktop table remains the same
        const DesktopTable = () => (
            <div className="hidden md:block bg-gray-800 rounded-lg overflow-hidden shadow-xl">
                <div className="grid grid-cols-4 gap-4 p-4 bg-gray-700 font-medium text-sm text-gray-300">
                    <div>TIER</div>
                    <div className="text-center">FINO</div>
                    <div className="text-center">{competitor.toUpperCase()}</div>
                    <div className="text-center">SAVINGS</div>
                </div>
                <div className="divide-y divide-gray-700">
                    {data.map((item, index) => (
                        <div key={index} className="grid grid-cols-4 gap-4 p-4 hover:bg-gray-750 transition-colors">
                            <div>
                                <div className="font-medium text-white">{item.tier}</div>
                                <div className="text-xs text-gray-400 mt-1">{item.ram} • {item.cpu}</div>
                            </div>
                            <div className="flex items-center justify-center text-white">
                                <DollarSign className="w-4 h-4 text-blue-400" />
                                <span className="font-medium">{item.fino}</span>
                            </div>
                            <div className="flex items-center justify-center text-gray-400">
                                <DollarSign className="w-4 h-4" />
                                <span>{item[competitorKey]}</span>
                            </div>
                            <div className="flex items-center justify-center">
                                <span className="px-3 py-1 bg-green-900/30 text-green-400 rounded-full text-sm">
                                    {item.savings}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );

        return (
            <>
                <DesktopTable />
                <div className="md:hidden">
                    {data.map((item, index) => (
                        <MobileTableCard key={index} item={item} />
                    ))}
                </div>
            </>
        );
    };

    return (
        <div className="bg-black min-h-screen text-gray-300 flex flex-col">
            <Header
                user={user}
                signInWithGitHub={signInWithGitHub}
                signOutWithGitHub={signOutWithGitHub}
            />

            <main className="container mx-auto px-4 py-8 md:py-12 flex-grow">
                {/* Rest of the component structure remains the same */}
                <div className="max-w-5xl mx-auto">
                    {/* Hero Section */}
                    <section className="text-center mb-8 md:mb-16">
                        <h1 className="text-3xl md:text-4xl font-bold text-white mb-4 md:mb-6">
                            Unmatched Value for Your Applications
                        </h1>
                        <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto">
                            Scale your applications at a fraction of the cost with Fino's optimized infrastructure.
                        </p>
                    </section>

                    {/* Key Benefits */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 mb-8 md:mb-16">
                        {[
                            {
                                title: "Cost-Effective",
                                description: "Save up to 12x compared to traditional platforms"
                            },
                            {
                                title: "Optimized Resources",
                                description: "Get more performance for every dollar spent"
                            },
                            {
                                title: "Flexible Scaling",
                                description: "Pay only for what you need as you grow"
                            }
                        ].map((benefit, index) => (
                            <div key={index} className="bg-gray-800 rounded-lg p-6 text-center">
                                <Check className="w-8 h-8 text-blue-400 mx-auto mb-4" />
                                <h3 className="text-lg font-semibold text-white mb-2">{benefit.title}</h3>
                                <p className="text-gray-400">{benefit.description}</p>
                            </div>
                        ))}
                    </div>

                    {/* Comparison Tables */}
                    <div className="space-y-8 md:space-y-12">
                        <section>
                            <h2 className="text-xl md:text-2xl font-semibold text-white mb-4 md:mb-6 text-center">
                                Fino vs Heroku Pricing
                            </h2>
                            <ComparisonTable data={herokuComparisons} competitor="Heroku" />
                        </section>

                        <section>
                            <h2 className="text-xl md:text-2xl font-semibold text-white mb-4 md:mb-6 text-center">
                                Fino vs Render Pricing
                            </h2>
                            <ComparisonTable data={renderComparisons} competitor="Render" />
                        </section>
                    </div>

                    {/* CTA Section */}
                    <section className="mt-8 md:mt-16 text-center bg-gray-800 rounded-lg p-6 md:p-8">
                        <h2 className="text-xl md:text-2xl font-bold text-white mb-3 md:mb-4">
                            Ready to Optimize Your Costs?
                        </h2>
                        <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
                            Join the growing community of developers who are saving money while getting better performance with Fino.
                        </p>
                        <a
                            href="/signup"
                            className="inline-block bg-blue-600 hover:bg-blue-700 text-white py-3 px-8 rounded-lg font-medium transition-colors"
                        >
                            Get Started with Fino
                        </a>
                    </section>

                    {/* Information Links */}
                    <div className="mt-8 md:mt-12 text-center text-sm text-gray-500">
                        <div className="space-y-2 md:space-y-0">
                            <span className="block md:inline">Prices sourced from official websites:</span>
                            <span className="block md:inline md:ml-2">
                                <a
                                    href="https://www.heroku.com/dynos"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-400 hover:underline inline-flex items-center"
                                >
                                    Heroku Pricing <ExternalLink className="w-4 h-4 ml-1" />
                                </a>
                                <span className="mx-2">|</span>
                                <a
                                    href="https://render.com/pricing#compute"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-400 hover:underline inline-flex items-center"
                                >
                                    Render Pricing <ExternalLink className="w-4 h-4 ml-1" />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default PriceComparison;